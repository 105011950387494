import vtkGenericRenderWindow from "@kitware/vtk.js/Rendering/Misc/GenericRenderWindow";
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useCallback,
} from "react";
import { IMeshItem } from "../Types/common";

export interface VTKContextType {
  genericRenderWindow: vtkGenericRenderWindow | undefined;
  viewerRef: React.MutableRefObject<HTMLElement | undefined>;
  handleResetRendererCamera: () => void;
  handleUpdateRenderer: () => void;
  loadRenderer: (meshList: IMeshItem[]) => void;
  updateGenericRenderWindow: (
    vtkGenericRenderWindow: vtkGenericRenderWindow
  ) => void;
  handleVisibilityChange: (ids: string[], visibility: boolean) => void;
  meshlList: IMeshItem[];
  isLoading: boolean;
  isEmptyCase: boolean;
}

const VTKContext = createContext<VTKContextType | undefined>(undefined);

const VtkProvider = ({ children }: { children: ReactNode }) => {
  const [genericRenderWindow, setGenericRenderWindow] = useState<
    vtkGenericRenderWindow | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [meshlList, setMeshlList] = useState<IMeshItem[]>([]);
  const viewerRef = useRef<HTMLElement>();

  const updateGenericRenderWindow = useCallback(
    (newGenericRenderWindow: vtkGenericRenderWindow) => {
      setGenericRenderWindow(newGenericRenderWindow);
    },
    []
  );

  const handleVisibilityChange = (ids: string[], visibility: boolean) => {
    const newMeshlList = meshlList?.map((group) => {
      const { items } = group;
      const newItems = items.map((item) => {
        const { itemId, renderingObject } = item;
        if (ids.includes(itemId)) {
          const newVisibility = visibility;
          renderingObject?.setVisibility(newVisibility);
          return { ...item, visibility: newVisibility };
        } else return item;
      });
      return { ...group, items: newItems };
    });
    handleUpdateRenderer();
    setMeshlList(newMeshlList);
  };

  const handleResetRendererCamera = () => {
    if (!genericRenderWindow) return;
    genericRenderWindow.getRenderer().resetCamera();
    genericRenderWindow.getRenderer().resetCameraClippingRange();

    const cam = genericRenderWindow.getRenderer().getActiveCamera();
    const size = genericRenderWindow.getOpenGLRenderWindow().getSize();
    const ratio = size[0] < size[1] ? size[0] / size[1] : size[1] / size[0];
    const ps = cam.getParallelScale();
    cam.setParallelScale(ps / ratio);

    genericRenderWindow.getRenderWindow().render();
  };
  const handleUpdateRenderer = () => {
    if (!genericRenderWindow) return;
    genericRenderWindow.getRenderWindow().render();
  };

  const loadRenderer = (meshList: IMeshItem[]) => {
    if (!genericRenderWindow) return;
    const renderer = genericRenderWindow.getRenderer();
    if (!renderer) return;
    const renderingObjectList = meshList
      .map(({ items }) => items.map(({ renderingObject }) => renderingObject))
      .flat();

    for (let i = 0; i < renderingObjectList.length; i++) {
      try {
        const renderingObject = renderingObjectList[i];
        if (!renderingObject) return;
        renderingObject.addToRenderer(renderer);
      } catch (err) {
        console.error(err);
      }
    }
    renderer.resetCamera();
    renderer.resetCameraClippingRange();
    const cam = genericRenderWindow.getRenderer().getActiveCamera();
    const size = genericRenderWindow.getOpenGLRenderWindow().getSize();
    const ratio = size[0] < size[1] ? size[0] / size[1] : size[1] / size[0];
    const ps = cam.getParallelScale();
    cam.setParallelScale(ps / ratio);
    genericRenderWindow.getRenderWindow().render();
    setMeshlList(meshList);
    setIsLoading(false);
  };

  return (
    <VTKContext.Provider
      value={{
        isEmptyCase: meshlList.length === 0,
        isLoading,
        genericRenderWindow,
        viewerRef,
        updateGenericRenderWindow,
        handleResetRendererCamera,
        handleUpdateRenderer,
        handleVisibilityChange,
        loadRenderer,
        meshlList,
      }}
    >
      {children}
    </VTKContext.Provider>
  );
};

export default VtkProvider;

export const useVTKContext = (): VTKContextType => {
  const context = useContext(VTKContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a VtkProvider");
  }
  return context;
};
