import { Stack } from "@mui/material";

const DialogPaper = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: React.CSSProperties;
}) => {
  return (
    <Stack
      sx={{
        width: "100% !important",
        height: "100% !important",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
export default DialogPaper;
