import { Dialogue } from "@imago-cloud/design-system";
import { isMobile } from "react-device-detect";
const Dialog = ({
  open = true,
  children,
}: {
  open?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Dialogue
      open={open}
      PaperProps={{
        sx: {
          zIndex: "88888",
          ...(isMobile && {
            width: "100vw !important",
            maxWidth: "100vw !important",
            minWidth: "100vw !important",
            minHeight: "100vh !important",
            height: "100vh !important",
            maxHeight: "100vh !important",
            borderRadius: "0px !important",
            margin: "0px",
          }),
          ...(!isMobile && {
            width: "100% !important",
            maxWidth: "1728px !important",
            minWidth: "1152px !important",
            minHeight: "576px !important",
            height: "900px !important",
            maxHeight: "auto !important",
            margin: "32px 64px",
          }),
        },
      }}
      disableBackdropClick
    >
      {children}
    </Dialogue>
  );
};
export default Dialog;
