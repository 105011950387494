import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ENVIRONMENT } from "./config";
import packageJson from "../package.json";
import { ThemeProvider } from "@imago-cloud/design-system";
import { ErrorBoundary } from "react-error-boundary";
import MessageProvider from "./Provider/MessageProvider";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RecoilNexus from "recoil-nexus";
import { UnknownErrorPage } from "./Pages/UnknownErrorPage";
import { ModuleProvider, ModuleProviderProps } from "@imago/imago-modules";
import ModalProvider from "./Provider/ModalProvider";
import VtkProvider from "./Provider/VtkProvider";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.debug(
  `#VIEWER_MODULE_CLIENT: ${ENVIRONMENT.toUpperCase()} v${packageJson.version}`
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ModuleProvider
        config={{
          env: ENVIRONMENT as ModuleProviderProps["config"]["env"],
        }}
      >
        <ErrorBoundary FallbackComponent={UnknownErrorPage}>
          <MessageProvider>
            <RecoilRoot>
              <RecoilNexus />
              <QueryClientProvider client={new QueryClient()}>
                <BrowserRouter>
                  <ModalProvider>
                    <VtkProvider>
                      <App />
                    </VtkProvider>
                  </ModalProvider>
                </BrowserRouter>
              </QueryClientProvider>
            </RecoilRoot>
          </MessageProvider>
        </ErrorBoundary>
      </ModuleProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
