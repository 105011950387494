import { RouteObject, useRoutes } from "react-router-dom";

import { Suspense, lazy } from "react";

const MainPage = lazy(() => import("../Pages/MainPage"));

export const routePath: RouteObject[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div />}>
        <MainPage />
      </Suspense>
    ),
    errorElement: <div />,
  },
];

export default function Routes() {
  const routes = useRoutes(routePath);
  return routes;
}
