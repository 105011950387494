import React, { createContext, useContext, useState, ReactNode } from "react";

export interface ModalContextType {
  onOpenModal: (component: React.ReactElement) => void;
  onCloseModal?: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<{
    open: boolean;
    component: React.ReactNode | null;
  }>({ open: false, component: null });

  const onOpenModal = (component: React.ReactElement) => {
    setModal({ open: true, component });
  };
  const onCloseModal = () => {
    setModal({ open: false, component: null });
  };

  return (
    <ModalContext.Provider value={{ onOpenModal, onCloseModal }}>
      <>
        {modal.open &&
          modal.component &&
          React.isValidElement(modal.component) &&
          React.cloneElement(modal.component as React.ReactElement<any>, {
            onClose: onCloseModal,
          })}
      </>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
