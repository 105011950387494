import { Cicon, Typography } from "@imago-cloud/design-system";
import { Stack } from "@mui/material";

import {
  Dialog,
  DialogContent,
  DialogPaper,
  DialogTitle,
} from "../Layouts/Dialog";
import { onErrorApp } from "../Utils/postMessage";
import i18next from "i18next";

export const UnknownErrorPage = () => {
  return (
    <Dialog>
      <DialogPaper>
        <DialogTitle onClose={onErrorApp} isClose />
        <DialogContent>
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "600px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Cicon
              icon="Warning"
              svgProps={{ width: "36px", height: "36px" }}
            />
            <Typography variant="H24" sx={{ textAlign: "center" }}>
              {i18next.t("viewer_modal.unknown_error_occured")}
            </Typography>
          </Stack>
        </DialogContent>
      </DialogPaper>
    </Dialog>
  );
};
