import { DialogueTitle } from "@imago-cloud/design-system";
import { CSSProperties } from "react";
const DialogTitle = ({
  children,
  onClose,
  sx,
  isClose = true,
}: {
  children?: React.ReactNode;
  onClose?: () => void;
  sx?: CSSProperties;
  isClose?: boolean;
}) => {
  return (
    <DialogueTitle
      isClose={isClose}
      onClose={onClose}
      component={"div"}
      sx={{
        width: "100%",
        "& > div:first-of-type": {
          minWidth: "calc(100% - 32px)",
          width: "100%",
        },
        ...sx,
      }}
    >
      {children}
    </DialogueTitle>
  );
};
export default DialogTitle;
