import { Dialog } from "./Layouts/Dialog";
import Routes from "./Routes";

function App() {
  return (
    <Dialog>
      <Routes />
    </Dialog>
  );
}

export default App;
