import {
  CLOUD_URL,
  CLOUD_M_URL,
  CROWN_URL,
  EXPLORER_MODULE_URL,
  MODULE_TESTBED_URL,
  BATCH_DEV_URL,
  BATCH_BUILD_PROTOCOL,
  BATCH_URL,
} from "../config";

const ALLOW_ORIGIN_LIST = [
  CLOUD_URL,
  CLOUD_M_URL,
  CROWN_URL,
  EXPLORER_MODULE_URL,
  MODULE_TESTBED_URL,
  BATCH_DEV_URL,
  BATCH_BUILD_PROTOCOL,
  BATCH_URL,
];

const getReferrer = () => {
  const normalizeUrl = (url: string) =>
    url.endsWith("/") ? url.slice(0, -1) : url;

  const referrer = document.referrer;
  if (!referrer) {
    const isElectronApp = navigator.userAgent.includes("Electron");
    if (isElectronApp) return "file://";
    else {
      console.error("invalid origin");
      return "";
    }
  } else return normalizeUrl(referrer);
};

export const onRedirectApp = (url: string) => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage(
    {
      message: "onRedirect",
      data: { url: url },
    },
    referrer
  );
  console.log("App onRedirect request");
};

export const onModuleSnackbar = (text: string) => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage(
    { message: "onModuleSnackbar", data: { text } },
    referrer
  );
  console.log("App onModuleSnackbar request");
};

export const onSuccessApp = (response: any) => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: "onSuccess", data: response }, referrer);
  console.log("App onSuccess request");
};

export const onCloseApp = () => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: "onClose" }, referrer);
  console.log("App onClose request");
};

export const onErrorApp = () => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: "onError" }, referrer);
  console.log("App onError request");
  onCloseApp();
};
