import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

export interface IMessageData {
  designCaseId: string;
  exactHost?: "batch" | undefined;
}

interface MessageContextType {
  message: IMessageData;
  isMessageReceived: boolean;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [isMessageReceived, setIsMessageReceived] = useState(false);
  const [message, setMessage] = useState<IMessageData>({
    designCaseId: "",
    exactHost: undefined,
  });

  const getIsValidMessageData = (search: string[], target: any) =>
    target && typeof target === "object" && search.some((key) => key in target);

  useEffect(() => {
    if (isMessageReceived) return;
    const handleMessage = ({ data }: MessageEvent) => {
      const { designCaseId, exactHost } = data || {};
      if (getIsValidMessageData(["designCaseId", "exactHost"], data)) {
        setMessage({ designCaseId, exactHost });
        setIsMessageReceived(true);
        if (!designCaseId) throw Error("designCaseId not found");
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [isMessageReceived]);

  return (
    <MessageContext.Provider value={{ message, isMessageReceived }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;

export const useMessage = (): MessageContextType => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};
